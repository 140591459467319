








































import { Component, Vue, Mixins } from 'vue-property-decorator';
import VModal from '@/components/VModal.vue';
import StoreFrontsMixin from '@/mixins/http/StoreFrontsMixin';
import { RegisterHttp } from '@/utils/Decorators';
import { RequestData } from '@/store/types/RequestStore';
import { namespace } from 'vuex-class';
import { Source } from '@/models';
import {v1 as uuid} from 'uuid';
import { StoreFront } from '@/models';
import { easync } from '@/utils/http';
import { showErrorToast } from '@/utils/Toast';
import { sourceStore } from '@/store/typed';

const createRequestTag = 'create_request_tag';
@Component({
  components: {
    VModal
  }
})
export default class CreateModal extends Mixins(StoreFrontsMixin) {
  @RegisterHttp(createRequestTag) createRequest: RequestData;

  modal!: VModal;
  content = '';

  private prototype: any = {
    name: null,
    appId: null,
    apiKey: uuid(),
  };

  canceled!: any | null;

  mounted() {
    this.modal = this.$refs.modal as VModal;
  }

  loading() {
    return this.createRequest?.loading;
  }

  get valid() {
    return this.prototype.name && this.prototype.appId && this.prototype.apiKey;
  }

  title() {
    return `Nuova app`;
  }

  get hasContent(): boolean {
    return this.$slots.default != null;
  }

  protected onCancel() {
    if (this.canceled) {
      this.canceled();
    }

    this.canceled = null;
    this.modal.hide();
    this.reset();
  }

  async create() {
    const [data, errors] = await easync(this.createStoreFront({ ...this.prototype, sourceId: sourceStore.currentSource?.id }, createRequestTag));
    if (errors) {
      showErrorToast("Si è verificato un errore in creazione");
      return;
    }

    this.$emit('success', data);
    this.modal.hide();
    this.reset();
  }

  protected onConfirm() {
    this.create();
  }

  protected onHidden() {
    if (this.canceled) {
      this.canceled();
    }
  }

  reset() {
    this.prototype = {
      name: null,
      sku: null,
      quantity: null
    };
  }

  show() {

    this.modal.show();
  }
}
