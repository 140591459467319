
















































import { Component, Prop, Vue, Mixins } from 'vue-property-decorator';
import VModal from '@/components/VModal.vue';
import StoreFrontsMixin from '@/mixins/http/StoreFrontsMixin';

import { StoreFront } from '@/models';
import { RequestData } from '@/store/types/RequestStore';
import { RegisterHttp } from '@/utils/Decorators';
import { Store } from 'vuex';
import { easync } from '@/utils/http';
import { showErrorToast } from '@/utils/Toast';
const editRequestTag = 'edit_store_front_request_tag';
const updateStockRequestTag = 'update_store_front_stock_request_tag';

@Component({
  components: {
    VModal
  }
})
export default class CreateModal extends Mixins(StoreFrontsMixin) {
  @RegisterHttp(editRequestTag) editRequest: RequestData;

  item: StoreFront | null = null;
  modal!: VModal;
  content = '';

  canceled!: any | null;

  mounted() {
    this.modal = this.$refs.modal as VModal;
  }

  get valid() {
    if (!this.item) {
      return false;
    }
    return this.item.name && this.item.appId && this.item.apiKey;
  }

  title() {
    return `Aggiorna ${this.item.name}`;
  }

  async update() {
    const [data, errors] = await easync(this.updateStoreFront({ ...this.item }, editRequestTag));
    if (errors) {
      showErrorToast("Si è verificato un errore in modifica");
      return;
    }

    this.$emit('success', this.item);
    this.modal.hide();
  }

  get hasContent(): boolean {
    return this.$slots.default != null;
  }

  protected onCancel() {
    if (this.canceled) {
      this.canceled();
    }

    this.canceled = null;
    this.modal.hide();
  }

  protected onConfirm() {
    if (this.valid) {
      this.update();
    }
  }

  protected onHidden() {
    if (this.canceled) {
      this.canceled();
    }
  }

  show(item: StoreFront) {
    this.modal.show();
    this.item = { ...item } as StoreFront;
  }
}
